import React from 'react';
import CandidateImage from '../photos/CandidateImage.jpg'; // Replace with your actual image path

const CandidateInformation = () => {
    return (
        <div className="bg-lightGray py-16 px-8">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-4xl font-bold text-primary text-center">
                    Candidate Information
                </h1>

                {/* Intro Section with Image */}
                <div className="mt-12 flex flex-col lg:flex-row items-center gap-0">
                    <div className="lg:w-1/2">
                        <p className="text-2xl font-semibold text-darkGray mb-0 lg:mb-0 mr-0">
                            We value our relationship with our candidates and assist them every step of the way
                            to ensure we find the right ‘fit’ for both the candidate and the client.
                        </p>
                    </div>
                    <div className="lg:w-1/2 flex justify-center mt-4">
                        <img
                            src={CandidateImage}
                            alt="Candidates"
                            className="w-full max-w-sm h-auto rounded-3xl object-cover mb-0 lg:mb-0"
                        />
                    </div>
                </div>

                <div className="mt-12">
                    <h2 className="text-2xl font-bold text-secondary mb-4">
                        As a candidate, we offer you the following guarantees:
                    </h2>
                    
                    <h3 className="text-xl font-semibold text-secondary mb-2">
                        We never:
                    </h3>
                    <ul className="list-disc list-inside text-lg text-darkGray ml-4 mb-8">
                        <li>Put you forward just to fill an ‘interview slot’.</li>
                        <li>Submit your CV to a client without your permission.</li>
                    </ul>
                    
                    <h3 className="text-xl font-semibold text-secondary mb-2">
                        We always:
                    </h3>
                    <ul className="list-disc list-inside text-lg text-darkGray ml-4">
                        <li>Service; candidates are the lifeblood of our business, therefore we make it a priority to surpass your expectations when providing career advice and job opportunities.</li>
                        <li>Get to know you on a personal level to better understand your needs, your personality type, and your aspirations.</li>
                        <li>Keep your details confidential and never share your personal information without your permission.</li>
                    </ul>
                </div>

                {/* Call to Action */}
                <div className="text-center mt-16">
                    <p className="text-lg text-darkGray mb-6">
                        To kick-start your sales career, please click the button below:
                    </p>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfmkhXXWssTuWcN5lV_S5Wd8d6aE6YCEKU1MwY9iwiQonEsDg/viewform?vc=0&c=0&w=1&flr=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-primary text-white rounded-full py-3 px-6 hover:bg-opacity-90 transition duration-300"
                    >
                        Start Your Career
                    </a>
                    <p className="text-lg text-darkGray mt-6">
                        We’ll be in contact with you within two working days.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CandidateInformation;
