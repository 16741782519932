import React from 'react';
import Dynamic from '../photos/D.jpg';
import Specialist from '../photos/S.jpg';
import Career from '../photos/C.jpg';
import EthosPhoto from '../photos/ethos.jpg';
import HeroImage from '../photos/hero.png'; // Import the hero image

const Home = () => {
    return (
        <div className="bg-white">
            {/* Hero Section */}
            <header className="bg-lightGray py-16">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center gap-12">
                    {/* Text Content */}
                    <div className="lg:w-1/2 text-center lg:text-left">
                        <h1 className="text-4xl font-bold text-secondary sm:text-5xl">
                            Welcome to Henderson Graye Recruitment!
                        </h1>
                        <p className="mt-4 text-lg text-darkGray">
                            Connecting top talent with leading companies. Discover how we can help you achieve your career or hiring goals.
                        </p>
                        <div className="mt-8">
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfmkhXXWssTuWcN5lV_S5Wd8d6aE6YCEKU1MwY9iwiQonEsDg/viewform?vc=0&c=0&w=1&flr=0"
                                className="bg-primary text-white rounded-full py-3 px-6 hover:bg-opacity-90 transition duration-300"
                            >
                                Get Started
                            </a>
                        </div>
                    </div>

                    {/* Hero Image */}
                    <div className="lg:w-1/2">
                        <img
                            src={HeroImage}
                            alt="Hero"
                            className="w-full h-[300px] rounded-3xl object-cover"
                        />
                    </div>
                </div>
            </header>

            {/* Our Ethos Section */}
            <section className="py-16 bg-white">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row gap-12">
                    {/* Text Content */}
                    <div className="lg:w-1/2 flex flex-col justify-center">
                        <h2 className="text-3xl font-bold text-secondary">
                            Our Ethos
                        </h2>
                        <p className="mt-4 text-lg text-gray-600">
                            A dynamic, tailored & conscientious approach. Specialists in recruitment for the direct marketing industry across the UK. We are experts in telephony and face-to-face sales avenues within the utilities and fundraising sectors. Our reputation is built on offering well-informed, genuine advice and unparalleled expertise. We uncover the best direct sales agents across the UK targeting precise postcodes to accelerate your business growth.
                        </p>
                        <p className="mt-4 text-lg text-gray-600">
                            As a supplier, we work hard to understand your culture, challenges and overall strategy so that we can add value to your business.
                        </p>
                        <p className="mt-4 text-lg text-gray-600">
                            As a Candidate, you are the lifeblood of our business; therefore, we make it a priority to surpass your expectations when providing career advice and job opportunities.
                        </p>
                    </div>

                    {/* Photo */}
                    <div className="lg:w-1/2 flex items-center">
                        <img
                            src={EthosPhoto}
                            alt="Our Ethos"
                            className="mt-4 w-full h-auto rounded-3xl object-cover"
                        />
                    </div>
                </div>
            </section>

            {/* Images Section */}
            <section className="py-16 bg-lightGray">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-bold text-secondary">
                        Why Us?
                    </h2>
                    <p className="mt-4 text-lg text-gray-600">
                        Discover why we are the best choice for both companies and candidates.
                    </p>
                </div>
                <div className="mt-12 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="rounded-3xl overflow-hidden shadow-lg">
                        <img src={Dynamic} alt="Dynamic Approach" className="w-full h-64 object-cover" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold text-secondary">Dynamic Approach</h3>
                            <p className="mt-2 text-gray-600">Tailored recruitment solutions to meet your specific needs.</p>
                        </div>
                    </div>
                    <div className="rounded-3xl overflow-hidden shadow-lg">
                        <img src={Specialist} alt="Specialist Expertise" className="w-full h-64 object-cover" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold text-secondary">Specialist Expertise</h3>
                            <p className="mt-2 text-gray-600">Experts in telephony and face-to-face sales avenues.</p>
                        </div>
                    </div>
                    <div className="rounded-3xl overflow-hidden shadow-lg">
                        <img src={Career} alt="Career Growth" className="w-full h-64 object-cover" />
                        <div className="p-4">
                            <h3 className="text-xl font-semibold text-secondary">Career Growth</h3>
                            <p className="mt-2 text-gray-600">Empowering candidates with opportunities to excel.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Call to Action */}
            <section className="bg-primary py-16">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-white">
                    <h2 className="text-3xl font-bold sm:text-4xl">
                        Ready to take the next step?
                    </h2>
                    <p className="mt-4 text-lg">
                        Whether you’re looking for your next role or the perfect hire, we’re here to help.
                    </p>
                    <div className="mt-8">
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLScsAHc1qDVV74a4OwQDPu_DHjzFSxLsQYt-eZOsqMevS03LwQ/viewform?vc=0&c=0&w=1&flr=0#"
                            className="bg-white text-primary rounded-full py-3 px-6 hover:bg-opacity-90 transition duration-300"
                        >
                            Contact Us
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;




