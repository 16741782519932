// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import ClientServices from './pages/ClientServices';
import CandidateInformation from './pages/CandidateInformation';
import LogoImage from './photos/logo.png'; 
const App = () => {
    return (
        <Router>
            <header className="bg-white shadow">
                <nav className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                    <div className="flex items-center">
                        <img 
                            src={LogoImage} 
                            alt="Recruitment Co. Logo" 
                            className="h-10 w-auto"
                        />
                    </div>
                    <div className="space-x-6">
                        <Link className="text-secondary hover:text-primary" to="/">Home</Link>
                        <Link className="text-secondary hover:text-primary" to="/client-services">Client Services</Link>
                        <Link className="text-secondary hover:text-primary" to="/candidate-information">Candidate Information</Link>
                    </div>
                </nav>
            </header>
            <div className="mt-4">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/client-services" element={<ClientServices />} />
                    <Route path="/candidate-information" element={<CandidateInformation />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;

