import React from 'react';
import ExecutiveSearchImage from '../photos/ExecutiveSearch.jpg';
import TemporaryStaffingImage from '../photos/TemporaryStaffing.jpg';
import TailoredRecruitmentImage from '../photos/TailoredRecruitment.jpg';

const ClientServices = () => {
    return (
        <div className="bg-lightGray py-16 px-8">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-4xl font-bold text-primary text-center">
                    Client Services
                </h1>
                <p className="mt-6 text-lg text-darkGray text-center">
                    We offer tailored recruitment services to help you find the right candidates. From executive search to temporary staffing, we’ve got you covered.
                </p>

                {/* Executive Search */}
                <div className="mt-12 flex flex-col lg:flex-row items-center gap-8">
                    <div className="lg:w-1/2 flex justify-center">
                        <img
                            src={ExecutiveSearchImage}
                            alt="Executive Search"
                            className="w-full max-w-md h-auto rounded-3xl object-cover"
                        />
                    </div>
                    <div className="lg:w-1/2">
                        <h2 className="text-3xl font-bold text-secondary mb-4">
                            Executive Search
                        </h2>
                        <p className="text-lg text-darkGray">
                            Our executive search services are designed to help you find top-level talent for your organization. We focus on identifying leaders who align with your company culture and goals, ensuring a perfect match for your executive team.
                        </p>
                    </div>
                </div>

                {/* Temporary Staffing */}
                <div className="mt-12 flex flex-col lg:flex-row-reverse items-center gap-8">
                    <div className="lg:w-1/2 flex justify-center">
                        <img
                            src={TemporaryStaffingImage}
                            alt="Temporary Staffing"
                            className="w-full max-w-md h-auto rounded-3xl object-cover"
                        />
                    </div>
                    <div className="lg:w-1/2">
                        <h2 className="text-3xl font-bold text-secondary mb-4">
                            Temporary Staffing
                        </h2>
                        <p className="text-lg text-darkGray">
                            Whether you need temporary support during peak seasons or specialized skills for a project, our temporary staffing services provide you with the flexibility and expertise you need. We have a robust network of qualified candidates ready to step in and help.
                        </p>
                    </div>
                </div>

                {/* Tailored Recruitment */}
                <div className="mt-12 flex flex-col lg:flex-row items-center gap-8">
                    <div className="lg:w-1/2 flex justify-center">
                        <img
                            src={TailoredRecruitmentImage}
                            alt="Tailored Recruitment"
                            className="w-full max-w-md h-auto rounded-3xl object-cover"
                        />
                    </div>
                    <div className="lg:w-1/2">
                        <h2 className="text-3xl font-bold text-secondary mb-4">
                            Tailored Recruitment Solutions
                        </h2>
                        <p className="text-lg text-darkGray">
                            We understand that every business is unique. That's why we offer tailored recruitment solutions that are customized to meet your specific needs. Our approach ensures that we find candidates who not only fit the job but also align with your company’s values and vision.
                        </p>
                    </div>
                </div>

                {/* Enquiry Section */}
                <div className="text-center mt-16">
                    <p className="text-lg text-darkGray mb-6">
                        To make an enquiry, please click the button below:
                    </p>
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScsAHc1qDVV74a4OwQDPu_DHjzFSxLsQYt-eZOsqMevS03LwQ/viewform?vc=0&c=0&w=1&flr=0"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-primary text-white rounded-full py-3 px-6 hover:bg-opacity-90 transition duration-300"
                    >
                        Make an Enquiry
                    </a>
                    <p className="text-lg text-darkGray mt-6">
                        We’ll be in contact with you within two working days.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ClientServices;
